<template>
  <div class="relationshipauth">
    <mt-header :title="$t('relationshipauth')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back1.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
    </mt-header>
    <step-item :step="3" />
    <div class="content">
      <div v-for="(item, index) in form.contact" :key="index + 'relativeContact'">
        <div class="separator">{{ $t('relativeContact') }} {{ form.contact.length > 1? index + 1: '' }}</div>
        <div>
          <div class="item">
            <div class="title">{{ $t('relationship') }}</div>
            <mt-field
              :placeholder="$t('relationshipPlaceholder')"
              readonly
              :state="item.relationCodeState"
              v-model="(Enum.contactRelation.filter(e => e.code == item.relationCode)[0]||{}).name"
              @click.native="onPopupPicker(contactRelationNew, (e) => {
                item.relationCode = e.code;
                BEHAVIOR_ADD({
                  id: `P03_C${(index * 3) + 1 < 10? '0' + ((index * 3) + 1): (index * 3) + 1}_S_RELATIONCODE`,
                  newValue: item.relationCode
                })
              })"
            >
              <mt-spinner v-if="Enum.contactRelationLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
              <i v-else class="iconfont">&#xe62a;</i>
            </mt-field>
          </div>
          <div class="item">
            <div class="title">{{ $t('hisOrHerMoblieNumber') }}</div>
            <div class="phone">
              <mt-field
                :placeholder="$t('hisOrHerMoblieNumberPlaceholder')"
                type="tel"
                v-mask="'#### #### ##'"
                :state="item.phoneState"
                v-model="item.phone"
                @blur.native.capture="BEHAVIOR_ADD({
                  id: `P03_C${(index * 3) + 2 < 10? '0' + ((index * 3) + 2): (index * 3) + 2}_I_CONTACTPHONE`,
                  newValue: item.phone
                })"
              >
                <img class="contact" src="../../assets/contact.png">
              </mt-field>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t('hisOrHerName') }}</div>
            <mt-field
              :placeholder="$t('hisOrHerNamePlaceholder')"
              :state="item.nameState"
              v-model="item.name"
              @blur.native.capture="BEHAVIOR_ADD({
                id: `P03_C${(index * 3) + 3 < 10? '0' + ((index * 3) + 3): (index * 3) + 3}_I_CONTACTNAME`,
                newValue: item.name
              })"
            ></mt-field>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in form.otherContact" :key="index + 'otherContact'">
        <div class="separator">{{ $t('otherContact') }} {{ form.otherContact.length > 1? index + 1: '' }}</div>
        <div>
          <div class="item">
            <div class="title">{{ $t('relationship') }}</div>
            <mt-field
              :placeholder="$t('relationshipPlaceholder')"
              readonly
              :state="item.relationCodeState"
              v-model="(Enum.otherContactRelation.filter(e => e.code == item.relationCode)[0]||{}).name"
              @click.native="onPopupPicker(otherContactRelationNew, (e) => {
                item.relationCode = e.code;
                BEHAVIOR_ADD({
                  id: `P03_C${
                    (form.contact.length * 3) + (index * 3) + 1 < 10
                      ? '0' + ((form.contact.length * 3) + (index * 3) + 1)
                      : (form.contact.length * 3) + (index * 3) + 1
                  }_S_RELATIONCODE`,
                  newValue: item.relationCode
                })
              })"
            >
              <mt-spinner v-if="Enum.otherContactRelationLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
              <i v-else class="iconfont">&#xe62a;</i>
            </mt-field>
          </div>
          <div class="item">
            <div class="title">{{ $t('hisOrHerMoblieNumber') }}</div>
            <div class="phone">
              <mt-field
                :placeholder="$t('hisOrHerMoblieNumberPlaceholder')"
                type="tel"
                v-mask="'#### #### ##'"
                :state="item.phoneState"
                v-model="item.phone"
                @blur.native.capture="BEHAVIOR_ADD({
                  id: `P03_C${
                    (form.contact.length * 3) + (index * 3) + 2 < 10
                      ? '0' + ((form.contact.length * 3) + (index * 3) + 2)
                      : (form.contact.length * 3) + (index * 3) + 2
                  }_I_CONTACTPHONE`,
                  newValue: item.phone
                })"
              >
                <img class="contact" src="../../assets/contact.png">
              </mt-field>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t('hisOrHerName') }}</div>
            <mt-field
              :placeholder="$t('hisOrHerNamePlaceholder')"
              :state="item.nameState"
              v-model="item.name"
              @blur.native.capture="BEHAVIOR_ADD({
                id: `P03_C${
                  (form.contact.length * 3) + (index * 3) + 3 < 10
                    ? '0' + ((form.contact.length * 3) + (index * 3) + 3)
                    : (form.contact.length * 3) + (index * 3) + 3
                  }_I_CONTACTNAME`,
                newValue: item.name
              })"
            ></mt-field>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules, phoneRepeatRules, nameRules } from '../../utils/rules';
import { applyStep } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import stepItem from '../../components/stepItem.vue'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'Relationshipauth',
  mixins: [mixin],
  components: {
    popupPicker,
    stepItem,
  },
  data() {
    return {
      isCheck: false,
      relativesLength: 2, // 亲属联系人个数
      otherLength: 3, // 其他联系人个数
      form: {
        contact: [],
        otherContact: [],
      }
    }
  },
  computed: {
    ...mapState(["apply", "user", "fromOther"]),
    contactRelationNew() {
      return this.Enum.contactRelation.filter(e => {
        if (e.code == 'SPOUSE') {
          // 配偶只能存在一个
          return this.form.contact.concat(this.form.otherContact).map(e => (e.relationCode)).indexOf('SPOUSE') < 0
        }
        return true
      });
    },
    otherContactRelationNew() {
      return this.Enum.otherContactRelation.filter(e => {
        if (e.code == 'SPOUSE') {
          // 配偶只能存在一个
          return this.form.contact.concat(this.form.otherContact).map(e => (e.relationCode)).indexOf('SPOUSE') < 0
        }
        return true
      });
    }
  },
  created() {
    gtmPageView(this)
    // 亲属联系人
    for (let i = 0; i < this.relativesLength; i++) {
      this.form.contact.push({
        relationCode: '', // 联系人关系
        name: '', // 联系人姓名
        phone: '', // 联系人手机号
      })
    }
    if (this.fromOther == 'Y') {
      this.otherLength = 1
    }
    // 其他联系人
    for (let i = 0; i < this.otherLength; i++) {
      this.form.otherContact.push({
        relationCode: '', // 联系人关系
        name: '', // 联系人姓名
        phone: '', // 联系人手机号
      })
    }
    Object.keys(this.form).forEach((val) => {
      try {
        if (typeof this.form[val] === 'string') {
          this.form[val] = this.apply[val] ?? ''
        } else {
          this.form[val].filter((item, index) => {
            Object.keys(this.form[val][index]).filter(name => {
              this.form[val][index][name] = this.apply[`${val}${index}${name}`] ?? ''
            })
          })
        }
      } catch(e) {
        console.log(e)
      }
    })
    // 亲属联系人 绑定监听
    for (let i = 0; i < this.relativesLength; i++) {
      this.dynamicWatch(this.form.contact, i, 'contact')
    }
    // 其他联系人 绑定监听
    for (let i = 0; i < this.otherLength; i++) {
      this.dynamicWatch(this.form.otherContact, i, 'otherContact')
    }
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P03_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P03_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_RELATIONSHIP(); // 亲属联系人
      this.GET_OTHER_RELATIONSHIP(); // 其他联系人
    },
    // 动态添加监听
    dynamicWatch(list, i, name) {
      // 监听关系
      this.$watch(() => {
        return list[i].relationCode + this.isCheck
      }, () => {
        this.onStorageItem(`${name}${i}relationCode`, list[i].relationCode)
        this.$set(
          list[i],
          'relationCodeState',
          this.isCheck ? emptyRules(list[i].relationCode) : null,
        )
      }, {
        immediate: true,
      })
      // 监听姓名
      this.$watch(() => {
        return list[i].name + this.isCheck
      }, () => {
        this.onStorageItem(`${name}${i}name`, list[i].name)
        this.$set(
          list[i],
          'nameState',
          this.isCheck ? nameRules(list[i].name) : null,
        )
      }, {
        immediate: true,
      })
      // 监听手机号
      this.$watch(() => {
        return list[i].phone + this.isCheck
      }, () => {
        this.onStorageItem(`${name}${i}phone`, list[i].phone)
        let all = this.form.contact.concat(this.form.otherContact)
        let phones = all.map(e => e.phone).filter(e => e)
        for (let i = 0; i < all.length; i++) {
          if (all[i].phoneState == 'warning') {
            this.$set(all[i], 'phoneState', phoneRepeatRules(all[i].phone, phones))
          }
        }
        this.$set(
          list[i],
          'phoneState',
          this.isCheck ? phoneRepeatRules(list[i].phone, phones) : null,
        )
      }, {
        immediate: true,
      })
    },
    // 校验输入内容
    validation(list) {
      let data = this.form.contact.concat(this.form.otherContact)
      for (let i = 0; i < data.length; i++) {
        if (list.indexOf('relationCode') >= 0 && data[i].relationCodeState != "success") {
          return this.$t('relationshipError')
        }
        if (list.indexOf('name') >= 0 && data[i].nameState != "success") {
          return this.$t('hisOrHerNameError')
        }
        if (list.indexOf('phone') >= 0 && data[i].phoneState != "success") {
          return this.$toast(data[i].phoneState == "warning"
          ? this.$t("hisOrHerMoblieNumberPlaceholder")
          : this.$t("hisOrHerMoblieNumberError"));
        }
      }
    },
    // 提交下一步
    async submit() {
      this.isCheck = true;
      await this.$nextTick(() => {})
      var error = this.validation(['relationCode', 'name', 'phone']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('04_RELATIONSHIP_AUTH', { content_name: '04_RELATIONSHIP_AUTH' })
        }
        // Google Analytics  REALATIONSHIP_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'REALATIONSHIP_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        currentStep: 3,
        totalSteps: 8,

        applyId: this.user.applyId,
        contacts: this.form.contact.map((e) => ({
          contactName: e.name,
          contactRelation: (this.Enum.contactRelation.filter(e => e.code == e.relationCode)[0]||{}).name,
          contactRelationCode: e.relationCode,
          contactPhone: e.phone.replace(/[^\d]/g, ''),
        })).concat(this.form.otherContact.map((e) => ({
          contactName: e.name,
          contactRelation: (this.Enum.otherContactRelation.filter(e => e.code == e.relationCode)[0]||{}).name,
          contactRelationCode: e.relationCode,
          contactPhone: e.phone.replace(/[^\d]/g, ''),
        })))
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/idcardauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('04_RELATIONSHIP_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.relationshipauth {
  background-color: #FBFBFF;
  .separator {
    color: #000000;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    margin: 15px 0;
    font-size: 18px;
    line-height: 24px;
  }
  .contact {
    height: 25px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .content {
    margin: 10px;
    .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .phone {
        border-radius: 6px;
        display: flex;
        background-color: white;
        .mint-cell {
          flex: 1;
        }
        &::before {
          content: '+52';
          color: #111111;
          padding-left: 10px;
          line-height: 46px;
        }
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
  }
  .iconfont {
    margin: 0 5px;
    color: #000000;
  }
  .bottom {
    padding: 40px 20px;
    .mint-button {
      border-radius: 20px;
    }
  }
}
</style>